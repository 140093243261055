<template>
   <div>
     <button
        class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2"  @click.prevent="addTimeline()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
        </svg>
        <span>Tambah</span>
    </button>

    <div id="list-timeline">
      <div
        v-for="(timeline, index) in timelines"
        :key="index"
        class="my-4 rounded-md bg-secondary/20 p-2"
      >
        <div class="relative mb-3 select-none">
          <div v-if="timeline.images && timeline.images[0]">
            <img
              :src="timeline.images[0].url"
              alt="home_image"
              class="rounded-md w-full h-auto"
            />
          </div>
          <div
            v-else
            class="w-full h-52 bg-primary grid place-items-center rounded-md"
          ></div>

          <div
            class="absolute top-0 left-0 w-full h-full z-10 bg-primary/20 rounded-md grid place-items-center cursor-pointer"
            @click.prevent="openInputImageTimeline(timeline, index)"
          >
            <svg
              v-if="!loadingImage"
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 text-white z-20"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 animate-spin"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
        </div>

        <div class="flex justify-end gap-1">
          <button
            class="flex flex-row gap-2 items-center bg-primary text-white rounded-md px-2 py-2"  @click.prevent="saveTimeline(timeline)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
            </svg>
            <span>Simpan</span>
          </button>
          <button
            class="flex flex-row gap-2 items-center bg-red-500 text-white rounded-md px-2 py-2"  @click.prevent="deleteTimeline(timeline.id)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
            <span>Hapus</span>
          </button>
        </div>
        <div class="grid grid-cols-2 gap-2">
          <div class="form-group">
            <label for="title-timeline">Title</label>
            <input
              id="title-timeline"
              v-model.lazy="timeline.title"
              type="text"
            />
          </div>

          <div class="form-group">
            <label for="date-timeline">Date</label>
            <input
              id="date-timeline"
              v-model.lazy="timeline.date"
              type="text"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="description-timeline">Description</label>
          <textarea
            id="description-timeline"
            v-model.lazy="timeline.description"
            name="description-timeline"
            cols="30"
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>

    <input
      id="uploadImageTimeline"
      type="file"
      accept="image/*"
      class="hidden"
      @input="uploadImageTimeline"
    />
  </div>
</template>


<script>
import { getFormData } from '~/common/helper/changer'
export default {
    props:{
      weddingCode: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
          timelineData: {},
          timelineBg: null,
          timelines: [],
          loadingImage: false
        }
    },
    mounted(){
      if(this.weddingCode) this.getWedding()
    },
    methods:{
       getWedding() {
           this.$axios.$get(`/api/v1/weddings/${this.weddingCode}/show`)
           .then(res => {
                if(res.data){
                  this.id = res.data.id
                  this.timelines = res.data.timelines
                }
           })
        },
        openInputImageTimeline(timeline, index) {
          this.timelineData = {
            ...timeline,
            index,
          }
          const fileInput = document.getElementById('uploadImageTimeline')
          fileInput.click()
        },

        refreshInputImageTimeline() {
          const fileInput = document.getElementById('uploadImageTimeline')
          fileInput.value = ''
          this.timelineBg = null
        },

        uploadImageTimeline(e) {
          const self = this
          self.loadingImage = true
          const reader = new FileReader()
          reader.onload = function () {
            self.timelines = self.timelines.map((timeline, index) => {
              if (self.timelineData.index === index) {
                return {
                  ...timeline,
                  images: [
                    {
                      url: reader.result,
                    },
                  ],
                }
              } else {
                return timeline
              }
            })
          }
          self.timelineBg = e.target.files[0]
          reader.readAsDataURL(e.target.files[0])
          self.loadingImage = false
        },

        saveTimeline(payload) {
          const self = this

          this.$swal
            .fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Save it!',
            })
            .then((result) => {
              if (result.isConfirmed) {
                self.$swal.fire('Saving your data...')
                self.$swal.showLoading()

                if (payload.id === '') {
                  const data = {
                    ...payload,
                    images: [self.timelineBg],
                  }
                  const formData = getFormData(data)

                  self.$axios.$post('/api/v1/timelines', formData).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.refreshInputImageTimeline()
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                } else {
                  if (this.timelineBg instanceof File)
                    payload.images[0] = this.timelineBg
                  else delete payload.images

                  const formData = getFormData(payload)  
                  self.$axios.$post('/api/v1/timelines/' + payload.id, formData ).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                }
              }
            })
        },

        addTimeline() {
          const self = this
          const checkAdd = self.timelines.find((el) => el.id === '')
          if (checkAdd) {
            self.$swal.fire('Warning', 'Save your current timeline', 'warning')
          } else {
            self.timelines.push({
              id: '',
              title: '',
              date: '',
              wedding_id: self.id,
              description: '',
              images: [],
            })
          }
        },

        deleteTimeline(id) {
          const self = this
          if (id === '') {
            self.timelines.pop()
          } else {
            this.$swal
              .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Save it!',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  self.$swal.fire('Saving your data...')
                  self.$swal.showLoading()

                 self.$axios.$delete('/api/v1/timelines/' + id).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                }
              })
          }
        }
    }
}
</script>
