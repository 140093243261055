<template>
    <div class="flex flex-col gap-5">

        <div class="flex gap-2 flex-row my-5 justify-center">
            <button v-for="(image, i) in images" :key="i"
                class="grid place-items-center h-56 w-96  rounded-lg relative bg-cover overflow-hidden bg-gray-300"
                @click.stop="openFile(i)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                        clip-rule="evenodd" />
                </svg>
                <img v-if="image.url" class="h-full bg-cover bg-center" :src="image.url" alt="asd">
                <input :id="`images${i}`" type="file" class="hidden" @change="setImage($event, i)">
            </button>
        </div>

        <div class="p-3 rounded-lg bg-secondary/30">
            <h5 class="text-xs font-bold font-roboto text-primary uppercase mb-3">Information</h5>

            <div class="flex flex-row gap-2">
                <div class="form-group w-full">
                    <label for="name">Title</label>
                    <input id="name" v-model.lazy="data.title" type="text" placeholder="Just Two of Us" />
                    <span v-if="errors.title && errors.title[0]" class="error">
                        {{ errors.title[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Date</label>
                    <input id="name" v-model.lazy="data.wedding_date" type="date" />
                    <span v-if="errors.wedding_date && errors.wedding_date[0]" class="error">
                        {{ errors.wedding_date[0] }}
                    </span>
                </div>
            </div>
        </div>

        <div class="p-3 rounded-lg bg-secondary/30">
            <h5 class="text-xs font-bold font-roboto text-primary uppercase mb-3">Husband Information</h5>

            <div v-if="weddingCode">
                <button
                    class="grid place-items-center h-56 w-96  rounded-lg relative bg-cover overflow-hidden bg-gray-300"
                    @click.stop="openFileBride('husband')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd" />
                    </svg>
                    <img v-if="husbandImage.url" class="h-full bg-cover bg-center" :src="husbandImage.url" alt="asd">
                    <input :id="`images-husband`" type="file" class="hidden" @change="setImageBride($event, 'husband')">
                </button>
            </div>
            <div class="gap-2 grid grid-cols-2">
                <div class="form-group w-full">
                    <label for="name">Name</label>
                    <input id="name" v-model.lazy="data.husband" type="text" placeholder="Aang Suparman" />
                    <span v-if="errors.husband && errors.husband[0]" class="error">
                        {{ errors.husband[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Nickname</label>
                    <input id="name" v-model.lazy="data.husband_nickname" type="text" placeholder="Aang" />
                    <span v-if="errors.husband_nickname && errors.husband_nickname[0]" class="error">
                        {{ errors.husband_nickname[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Title</label>
                    <input id="name" v-model.lazy="data.husband_front_title" type="text" placeholder="Drs." />
                    <span v-if="errors.husband_front_title && errors.husband_front_title[0]" class="error">
                        {{ errors.husband_front_title[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Back Title</label>
                    <input id="name" v-model.lazy="data.husband_back_title" type="text" placeholder="S.T" />
                    <span v-if="errors.husband_back_title && errors.husband_back_title[0]" class="error">
                        {{ errors.husband_back_title[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Father</label>
                    <input id="name" v-model.lazy="data.husband_father" type="text" placeholder="Ong Suparman" />
                    <span v-if="errors.husband_father && errors.husband_father[0]" class="error">
                        {{ errors.husband_father[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Mother</label>
                    <input id="name" v-model.lazy="data.husband_mother" type="text" placeholder="Ningsih" />
                    <span v-if="errors.husband_mother && errors.husband_mother[0]" class="error">
                        {{ errors.husband_mother[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Twitter</label>
                    <input id="name" v-model.lazy="data.husband_twitter" type="text" placeholder="suparman.exists" />
                    <span v-if="errors.husband_twitter && errors.husband_twitter[0]" class="error">
                        {{ errors.husband_twitter[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Instagram</label>
                    <input id="name" v-model.lazy="data.husband_instagram" type="text" placeholder="superman.exists" />
                    <span v-if="errors.husband_instagram && errors.husband_instagram[0]" class="error">
                        {{ errors.husband_instagram[0] }}
                    </span>
                </div>
            </div>
        </div>

        <div class="p-3 rounded-lg bg-secondary/30">
            <h5 class="text-xs font-bold font-roboto text-primary uppercase mb-3">Wife Information</h5>

            <div v-if="weddingCode">
                <button
                    class="grid place-items-center h-56 w-96  rounded-lg relative bg-cover overflow-hidden bg-gray-300"
                    @click.stop="openFileBride('wife')">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd" />
                    </svg>
                    <img v-if="wifeImage.url" class="h-full bg-cover bg-center" :src="wifeImage.url" alt="asd">
                    <input :id="`images-wife`" type="file" class="hidden" @change="setImageBride($event, 'wife')">
                </button>
            </div>
            <div class="gap-2 grid grid-cols-2">
                <div class="form-group w-full">
                    <label for="name">Name</label>
                    <input id="name" v-model.lazy="data.wife" type="text" placeholder="Titi Suparman" />
                    <span v-if="errors.wife && errors.wife[0]" class="error">
                        {{ errors.wife[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Nickname</label>
                    <input id="name" v-model.lazy="data.wife_nickname" type="text" placeholder="Titi" />
                    <span v-if="errors.wife_nickname && errors.wife_nickname[0]" class="error">
                        {{ errors.wife_nickname[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Title</label>
                    <input id="name" v-model.lazy="data.wife_front_title" type="text" placeholder="Drs." />
                    <span v-if="errors.wife_front_title && errors.wife_front_title[0]" class="error">
                        {{ errors.wife_front_title[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Back Title</label>
                    <input id="name" v-model.lazy="data.wife_back_title" type="text" placeholder="S.Kom" />
                    <span v-if="errors.wife_back_title && errors.wife_back_title[0]" class="error">
                        {{ errors.wife_back_title[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Father</label>
                    <input id="name" v-model.lazy="data.wife_father" type="text" placeholder="Slamet Wijanarko" />
                    <span v-if="errors.wife_father && errors.wife_father[0]" class="error">
                        {{ errors.wife_father[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Mother</label>
                    <input id="name" v-model.lazy="data.wife_mother" type="text" placeholder="Painem" />
                    <span v-if="errors.wife_mother && errors.wife_mother[0]" class="error">
                        {{ errors.wife_mother[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Twitter</label>
                    <input id="name" v-model.lazy="data.wife_twitter" type="text" placeholder="titi.unyu" />
                    <span v-if="errors.wife_twitter && errors.wife_twitter[0]" class="error">
                        {{ errors.wife_twitter[0] }}
                    </span>
                </div>
                <div class="form-group w-full">
                    <label for="name">Instagram</label>
                    <input id="name" v-model.lazy="data.wife_instagram" type="text" placeholder="titi.unyu_ig" />
                    <span v-if="errors.wife_instagram && errors.wife_instagram[0]" class="error">
                        {{ errors.wife_instagram[0] }}
                    </span>
                </div>
            </div>
        </div>

        <div class="p-3 rounded-lg bg-secondary/30">
            <h5 class="text-xs font-bold font-roboto text-primary uppercase mb-3">Address Information</h5>

            <div class="form-group w-full">
                <label for="name">Address</label>
                <input id="name" v-model.lazy="data.address" type="text" placeholder="Jakarta" />
                <span v-if="errors.address && errors.address[0]" class="error">
                    {{ errors.address[0] }}
                </span>
            </div>
            <div class="form-group w-full">
                <label for="name">Address Detail</label>
                <textarea id="surah-detail" v-model.lazy="data.address_detail" name="surah-detail" cols="30" rows="5"
                    placeholder="lorem ipsum dolor siamet" />
                <span v-if="errors.address_detail && errors.address_detail[0]" class="error">
                    {{ errors.address_detail[0] }}
                </span>
            </div>
            <div class="form-group w-full">
                <label for="name">Google Maps Link</label>
                <input id="name" v-model.lazy="data.google_maps_link" type="text" placeholder="www.maps.google.com" />
                <span v-if="errors.google_maps_link && errors.google_maps_link[0]" class="error">
                    {{ errors.google_maps_link[0] }}
                </span>
            </div>
        </div>


        <div class="p-3 rounded-lg bg-secondary/30">
            <h5 class="text-xs font-bold font-roboto text-primary uppercase mb-3">Surah Information</h5>

            <div class="form-group w-full">
                <label for="name">Name</label>
                <input id="name" v-model.lazy="data.surah" type="text" placeholder="An-Nisa 21" />
                <span v-if="errors.surah && errors.surah[0]" class="error">
                    {{ errors.surah[0] }}
                </span>
            </div>
            <div class="form-group w-full">
                <label for="name">Surah Detail</label>
                <textarea id="surah-detail" v-model.lazy="data.surah_detail" name="surah-detail" cols="30" rows="5"
                    placeholder="lorem ipsum dolor siamet" />
                <span v-if="errors.surah_detail && errors.surah_detail[0]" class="error">
                    {{ errors.surah_detail[0] }}
                </span>
            </div>
        </div>

        <div class="form-group">
            <label for="song" class="mb-2">Song</label>
            <select id="song" v-model="songSelected" name="song">
                <option disabled>Please select song</option>
                <option v-for="(song, index) in songs.data" :key="index" :value="song.id">
                    {{ song.name }}
                </option>
            </select>
            <span v-if="errors.song_ids && errors.song_ids[0]" class="error">
                {{ errors.song_ids[0] }}
            </span>
        </div>

        <div class="form-group">
            <label for="theme" class="mb-2">Theme</label>
            <select id="theme" v-model="data.theme_id" name="theme">
                <option disabled>Please select Theme</option>
                <option v-for="(theme, index) in themes" :key="index" :value="theme.id">
                    {{ theme.code }} - {{ theme.title }} - {{ theme.currency === 'idr' ? 'Rp.' : '$' }} {{
        theme.price.toLocaleString('id-ID')
}}
                </option>
            </select>
        </div>


        <div class="form-group w-full">
            <label for="message-wa">Whatsapp Message Template</label>
            <details>
                <summary class="text-sm italic text-gray-400 leading-loose">details</summary>
                <p class="bg-tertiary/20 text-xs rounded-sm p-5" v-html="infoWaTemplate"></p>
            </details>
            <textarea id="message-wa" v-model.lazy="data.message" name="message-wa" cols="30" rows="10"
                placeholder="lorem ipsum dolor siamet" maxlength="1500" />
            <span v-if="errors.message && errors.message[0]" class="error">
                {{ errors.message[0] }}
            </span>
        </div>

        <div v-if="is_admin" class="form-group">
            <label for="user" class="mb-2">User</label>
            <select id="user" v-model="userSelected" name="user">
                <option disabled>Please select user</option>
                <option v-for="(user, index) in users.data" :key="index" :value="user.id">
                    {{ user.name }} - <span class="text-gray-300 text-xs italic">{{ user.email }}</span>
                </option>
            </select>
        </div>


        <div class="my-2 flex justify-end gap-2">
            <button class="flex flex-row gap-2 items-center text-white bg-primary rounded-md px-2 py-2" @click="submit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
                </svg>
                <span>Save</span>
            </button>
        </div>
    </div>
</template>


<script>
import { getFormData } from '~/common/helper/changer'
import { isAdmin } from '~/common/helper/validator'
export default {
    props: {
        weddingCode: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            data: {
                id: '',
                title: '',
                wedding_date: '',
                google_maps_link: '',
                address: '',
                address_detail: '',
                surah: '',
                surah_detail: '',
                message: '',

                husband: '',
                husband_nickname: '',
                husband_front_title: '',
                husband_back_title: '',
                husband_father: '',
                husband_mother: '',
                husband_instagram: '',
                husband_twitter: '',

                wife: '',
                wife_nickname: '',
                wife_front_title: '',
                wife_back_title: '',
                wife_father: '',
                wife_mother: '',
                wife_instagram: '',
                wife_twitter: '',
                theme_id: 1,
                song_ids: '',
            },
            songs: {},
            songSelected: null,
            guest: '',
            url: '',
            errors: [],
            themes: [],
            images: [
                {
                    url: '',
                    file: null
                },
                {
                    url: '',
                    file: null
                },
                {
                    url: '',
                    file: null
                },
            ],
            husbandImage: { url: null, file: null },
            wifeImage: { url: null, file: null },
            users: {},
            userSelected: null,
            infoWaTemplate: `
               <table>
                <tbody>
                    <tr>
                        <td><b>{{1}}</b></td>
                        <td>is husband fullname</td>
                         <td><b>{{2}}</b></td>
                        <td>is wife fullname</td>
                    </tr>
                     <tr>
                        <td><b>{{3}}</b></td>
                        <td>is husband nickname</td>
                         <td><b>{{4}}</b></td>
                        <td>is wife nickname</td>
                    </tr>
                     <tr>
                        <td><b>{{url}}</b></td>
                        <td>is url for guest</td>
                        <td><b>{{guest}}</b></td>
                        <td>is guest name</td>
                    </tr>
                <tbody>
               </table>
            `
        }
    },
    computed: {
        is_admin() {
            return isAdmin(this.$auth.user)
        }
    },
    mounted() {
        if (this.weddingCode) this.getWedding()
        if (this.is_admin) this.getUsers()

        this.getSongList()
        this.getThemes()
    },
    methods: {
        getWedding() {
            this.$axios.$get(`/api/v1/weddings/${this.weddingCode}/show`)
                .then(res => {
                    if (res.data) {
                        this.songSelected = res.data.songs[0]?.id ?? null
                        this.data = { ...res.data, message: res.data.message ? res.data.message.replace('%0a', '\n') : '' }
                        this.data.images.forEach((el, i) => {
                            this.images[i].url = el.url
                        });
                        this.husbandImage.url = res.data.husband_image.url ?? ''
                        this.wifeImage.url = res.data.wife_image.url ?? ''
                        this.userSelected = res.data.user_id
                    }
                })
        },
        getUsers() {
            this.$axios.$get('/api/v1/users')
                .then((res) => {
                    if (res.success) {
                        const data = res.data
                        this.users = data
                    }
                })
        },
        submit() {
            const self = this
            this.$swal
                .fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Save it!',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        self.$swal.fire('Saving your data...')
                        self.$swal.showLoading()

                        const data = { ...this.data }
                        data.song_ids = [this.songSelected]
                        data.images = self.images.filter(el => el.file !== null).map(el => el.file)

                        if (self.husbandImage.file) data.husband_image_file = self.husbandImage.file
                        if (self.wifeImage.file) data.wife_image_file = self.wifeImage.file

                        delete data.guests
                        delete data.songs
                        delete data.theme
                        delete data.gallery
                        delete data.events
                        delete data.gifts
                        delete data.timelines

                        if (self.data.id) {
                            self.update(data);
                        } else {
                            self.store(data)
                        }
                    }
                })
        },
        store(payload) {
            const self = this
            let data = { ...payload }
            if (self.is_admin) data = { ...payload, user_id: self.userSelected }
            const formData = getFormData(data)

            self.$axios.$post(`/api/v1/weddings`, formData).then((res) => {
                if (res.success) {
                    self.$swal.hideLoading()
                    self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                            if (res2.isConfirmed) {
                                self.$router.push({
                                    path: `/wedding`,
                                })
                            }
                        })
                }
            }).catch((error) => {
                self.errors = error?.response.data.errors
                self.$swal.fire('Error!', error?.response.data.message, 'error')
            })
        },
        update(payload) {
            const self = this
            const formData = getFormData(payload)

            self.$axios.$post(`/api/v1/weddings/${payload.id}`, formData).then((res) => {
                if (res.success) {
                    self.$swal.hideLoading()
                    self.$swal.fire('Saved!', res.message, 'success')
                        .then((res2) => {
                            if (res2.isConfirmed) {
                                self.getWedding()
                            }
                        })
                }
            }).catch((error) => {
                self.errors = error?.response.data.errors
                self.$swal.fire('Error!', error?.response.data.message, 'error')
            })
        },
        getSongList() {
            const self = this
            this.$axios.$get('/api/v1/songs').then((res) => {
                if (res.success) self.songs = res.data
            })
        },
        getThemes() {
            const self = this
            this.$axios.$get('/api/v1/themes/publish/list').then((res) => {
                if (res.success) self.themes = res.data
            })
        },
        openFile(index) {
            const input = document.getElementById(`images${index}`)
            input.click()
        },
        setImage(e, i) {
            const self = this
            const reader = new FileReader()
            reader.onload = function () {
                self.images[i].url = reader.result
            }
            self.images[i].file = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
        },
        openFileBride(detail) {
            const input = document.getElementById(`images-${detail}`)
            input.click()
        },
        setImageBride(e, detail) {
            const self = this
            const reader = new FileReader()
            reader.onload = function () {
                if (detail === 'husband')
                    self.husbandImage.url = reader.result
                else
                    self.wifeImage.url = reader.result
            }

            if (detail === 'husband')
                self.husbandImage.file = e.target.files[0]
            else
                self.wifeImage.file = e.target.files[0]

            reader.readAsDataURL(e.target.files[0])
        }
    }
}
</script>

<style scoped>
textarea,
input,
select {
    background: white !important;
    border: white !important;
}
</style>