<template>
  <div id="gallery" class="flex flex-row flex-wrap gap-2">
    <div
      v-for="(gallery, index) in galleries"
      :key="index"
      class="relative"
    >
      
      <img v-lazy-load :src="gallery.url" alt="" class="h-40 w-auto bg-gray-400 rounded-sm" />
      <button
        class="rounded-sm p-1 bg-red-500 text-white absolute right-1 top-1"
        @click="deleteGallery(gallery.id)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </button>
    </div>
    <div
      class="h-40 w-40 grid place-items-center bg-primary cursor-pointer"
      @click="openInputImageGallery"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-8 w-8 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 4v16m8-8H4"
        />
      </svg>
    </div>
    <input
      id="uploadImageGallery"
      type="file"
      accept="image/*"
      class="hidden"
      @input="uploadImageGallery"
    />
  </div>
</template>


<script>
import { getFormData } from '~/common/helper/changer'
export default {
    props:{
      weddingCode: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
            galleries : [],
            id: ''
        }
    },
    mounted(){
      if(this.weddingCode) this.getWedding()
    },
    methods:{
       getWedding() {
           this.$axios.$get(`/api/v1/weddings/${this.weddingCode}/show`)
           .then(res => {
                if(res.data){
                  this.id = res.data.id
                  this.galleries = res.data.gallery
                }
           })
        },
        openInputImageGallery() {
            const fileInput = document.getElementById('uploadImageGallery')
            fileInput.click()
        },
        uploadImageGallery(e) {
            const self = this
            const payload = {
            wedding_id: this.id,
            images: [...e.target.files],
            }

            const formData = getFormData(payload)
            self.$swal.fire('Uploading...')
            self.$swal.showLoading()
            self.$axios.$post('/api/v1/galleries', formData).then((res) => {
            if (res.success) {
                const fileInput = document.getElementById('uploadImageGallery')
                fileInput.value = ''

                self.$swal.hideLoading()
                self.$swal.fire('Saved!', res.message, 'success').then((res2) => {
                if (res2.isConfirmed) {
                    self.getWedding()
                }
                })
            } else {
                self.$swal.fire('Error!', res.message, 'error')
            }
            })
        },
        deleteGallery(id) {
            const self = this
            if (id === '') {
            self.galleries.pop()
            } else {
            this.$swal
                .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Do it!',
                })
                .then((result) => {
                if (result.isConfirmed) {
                    self.$swal.fire('Deleting image..')
                    self.$swal.showLoading()

                    self.$axios.$delete('/api/v1/galleries/' + id).then((res) => {
                    if (res.success) {
                        self.$swal.hideLoading()
                        self.$swal
                        .fire('Success!', res.message, 'success')
                        .then((res2) => {
                            if (res2.isConfirmed) {
                            self.getWedding()
                            }
                        })
                    } else {
                        self.$swal.fire('Error!', res.message, 'error')
                    }
                    })
                }
                })
            }
        }
    }
}
</script>
