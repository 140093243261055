<template>
    <nav class="absolute top-0 bg-transparent right-0 left-0">
        <div class="flex flex-row lg:container lg:mx-auto justify-center lg:justify-between pt-6">
            <a href="https://bellioo.com" class="font-bold text-xl text-white">
                Bellioo
            </a>
            <ul class="hidden lg:flex flex-row gap-x-10 text-white text-xl">
                <li class="hover:text-tertiary hover:underline cursor-pointer">
                    <nuxt-link :to="localePath('/')">{{ $t('navigation.home') }}</nuxt-link>
                </li>
                <li class="hover:text-tertiary hover:underline cursor-pointer">
                    <nuxt-link :to="localePath('feature')">{{ $t('navigation.feature') }}</nuxt-link>
                </li>
                <li class="hover:text-tertiary hover:underline cursor-pointer">
                    <nuxt-link :to="localePath('catalog')">{{ $t('navigation.catalog') }}</nuxt-link>
                </li>
                <!-- <li class="hover:text-tertiary hover:underline cursor-pointer">
                    <nuxt-link :to="localePath('contact')">{{ $t('navigation.contact') }}</nuxt-link>
                </li> -->
                <li class="hover:text-tertiary hover:underline cursor-pointer">
                    <nuxt-link :to="localePath('login')">{{ $t('navigation.login') }}</nuxt-link>
                </li>
                <li class="cursor-pointer flex text-sm uppercase items-center gap-2  font-roboto">
                    <nuxt-link v-for="(locale, index) in availableLocales" :key="index"
                        :class="$i18n.locale === locale.code && 'font-bold'" :to="switchLocalePath(locale.code)">
                        <img :src="require(`@/assets/flags/${locale.code}.svg`)" width="20"
                            :alt="`flag-${locale.code}`">
                    </nuxt-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavigationComponent',
    computed: {
        availableLocales() {
            return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
        }
    }
}
</script>