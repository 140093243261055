<template>
    <div>
        <div v-if="!id" class="form-group">
            <label for="name">File</label>
            <input
                id="uploadImage"
                type="file"
                accept=".mp3,audio/*"
                @input="uploadImage"
            />
        </div>
        <div class="form-group">
            <label for="name">Name</label>
            <input id="name" v-model.lazy="song.name" type="name" />
        </div>
        <div class="my-2 flex justify-end gap-2">
            <button class="flex flex-row gap-2 items-center text-white bg-primary rounded-md px-2 py-2" @click="submit">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
                </svg>
                <span>Save</span>
            </button>
        </div>
    </div>
</template>

<script>
import { getFormData } from '~/common/helper/changer'

export default {
    name: "SongForm",
    props:{
      id: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
            song: {
                name: '',
                song: ''
            }
        }
    },
    mounted(){
        if (this.id) this.getSong()
    },
    methods:{
        uploadImage(e) {
            const self = this
            const reader = new FileReader()
            reader.onload = function () {
                self.song.file = {
                    url: reader.result,
                }
            }
            self.song = {
                name: e.target.files[0].name,
                song: e.target.files[0]

            } 
            reader.readAsDataURL(e.target.files[0])
        },
        getSong() {
            this.$swal.fire('loading song data')
            this.$swal.showLoading()

            this.$axios.$get(`/api/v1/songs/${this.id}`).then((res) => {
                    if (res.success) this.song.name = res.data.name
                })
                .finally(() => this.$swal.close())
        },
        submit() {
            const self = this
            const data = self.id ? self.song : getFormData(self.song)

            self.$swal
            .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Save it!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    self.$swal.fire('Saving your data...')
                    self.$swal.showLoading()

                    if(self.id){
                        self.update(data)
                    }else{
                       self.store(data)
                    }

                 }
            })
        },
        store(payload){
            const self = this
             self.$axios.$post('/api/v1/songs', payload)
             .then((res) => {
                if (res.success) {
                    self.$swal.hideLoading()
                    self.$swal
                    .fire('Saved!', res.message, 'success')
                    .then((res2) => {
                        if (res2.isConfirmed) {
                            self.$router.push('/songs')
                        }
                    })
                }
            })
            .catch(error => {
                self.$swal.fire('Error!',error.response.data.message, 'error')
            })
        },
        update(payload){
            const self = this
            self.$axios.$patch( '/api/v1/songs/' + self.id, payload)
            .then((res) => {
                if (res.success) {
                    self.$swal.hideLoading()
                    self.$swal
                    .fire('Saved!', res.message, 'success')
                    .then((res2) => {
                        if (res2.isConfirmed) {
                            self.$router.push('/songs')
                        }
                    })
                    
                }
            })
            .catch(error => {
                    self.$swal
                .fire('Error!',error.response.data.message, 'error')
            })
        }
    }
}
</script>