<template>
    <div>
      <form action="" class="mx-auto constantia">
        <div id="form" class="flex flex-col flex-1 md:container md:mx-auto">
          <div>
            <input
              id="name"
              v-model="name"
              type="text"
              :placeholder="language === 'id' ? 'Nama Tamu' : 'Your Name'"
              class="w-full"
            />
          </div>
          <div class="custom-radio">
            <input
              id="attendance"
              v-model="isAttended"
              type="radio"
              name="hadir"
              value="1"
            />
            <span class="checkmark" @click="isAttended = `1`"></span>
            <label v-if="language == 'id'" for="attendance" style="letter-spacing: 0.08em" class="constantia text-xs">Ya, Saya akan Datang</label>
            <label v-else-if="language == 'en'" for="attendance" style="letter-spacing: 0.08em" class="constantia text-xs">Yes, I will be there</label>
          </div>

          <div class="custom-radio">
            <input
              id="attendance2"
              v-model="isAttended"
              type="radio"
              name="hadir"
              value="0"
            />
            <span class="checkmark" @click="isAttended = `0`"></span>
            <label v-if="language == 'id'" for="attendance2" style="letter-spacing: 0.08em" class="constantia text-xs">Maaf, Saya Tidak Bisa Datang</label>
            <label v-else-if="language == 'en'" for="attendance2" style="letter-spacing: 0.08em" class="constantia text-xs">No, I can’t be there</label>
          </div>

          <div id="guest">
            <input
              v-model="guestNumber"
              type="number"
              :placeholder="language === 'id' ? 'Jumlah Tamu' : 'Number of guest'"
              class="w-full"
              min="1"
              max="5"
            />
          </div>
          <div id="wish" class="w-full">
            <textarea
              v-model="wish"
              name="wishes"
              cols="30"
              rows="10"
              :placeholder="language === 'id' ? 'Tulisan Ucapan Pernikahanmu' : 'Type your wishes here'"
              class="w-full"
            >
            </textarea>
          </div>
        </div>
        <div id="button-send" class="grid mt-3 place-items-center">
          <button
            :class="`${theme}__btn`"
            :disabled="loading || !name"
            @click.prevent="sendWish()"
          >
            <div class="flex flex-row items-center justify-center gap-2">
              <span v-if="loading" class="animate-spin">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-if="language == 'id'" class="capitalize">{{
                loading ? 'Mengirim...' : 'Kirim'
              }}</span>
              <span v-else-if="language == 'en'" class="capitalize">{{
                loading ? 'Sending...' : 'Send'
              }}</span>
            </div>
          </button>
        </div>
      </form>

      <div id="list" class="md:container md:mx-auto">
        <div class="my-5 text-center p-5 border border-gray-400 rounded-md" >
          <span  :class="`${theme}__wedding_wishes`">wedding wishes</span>
          <div class="flex flex-row h-40 px-2 py-3 overflow-auto" style="height: 387px;">
            <ul v-if="attendances.length > 0" class="list-none">
              <li v-for="(att, i) in attendances" :key="i" class="my-1">
                <div class="flex flex-row items-center gap-2 text-left">
                  <span id="point" class="w-3 h-3 rounded-full" style="background-color:#314260;"></span>
                  <div
                    class="flex flex-col md:flex-row items-start md:items-center md:gap-2 mb-1 md:mb-0"
                  >
                    <div class="font-semibold capitalize">{{ att.name }}</div>
                    <span class="text-xs text-gray-500">{{
                      $moment(att.created_at).fromNow()
                    }}</span>
                  </div>
                </div>
                <div class="ml-5 text-left">
                  {{ att.description }}
                </div>
              </li>
            </ul>
            <ul v-else class="w-full mt-2 text-center list-none">
              <li class="opacity-70">be the first to send wish</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props:{
      attendances: {
         type: Array,
         required: true,
      },
      guest: {
        type: Object,
        required: false,
        default: () => {}
      },
      wedding: {
         type: Object,
         required: true,
      },
      themeCode: {
         type: String,
         required: false,
         default: 'JRIS3X'
      },
      language:{
         type: String,
         required: false,
         default: 'en' 
      }
    },
    data() {
        return {
            name : this.guest.name ?? '',
            isAttended: null,
            guestNumber: null,
            wish: null,
            loading: false,
        }
    },
    computed:{
      theme(){
        let theme = ''
        switch (this.themeCode) {
          case 'JRIS3X':
            theme = 'theme1'
            break;
           case 'N7790A':
            theme = 'theme2'
            break;
          default:
            theme = 'theme1'
            break;
        }

        return theme
      }
    },
    watch:{
        isAttended(val){
            const guest = document.getElementById('guest')?.classList
            const wish = document.getElementById('wish')?.classList
            const send = document.getElementById('button-send')?.classList

            if (val === '1' || null) {
                if (!guest.contains('open')) guest?.toggle('open')
                if (!wish.contains('open')) wish?.toggle('open')
                this.guestNumber = 1
            } else if (val === '0') {
                if (guest.contains('open')) guest?.toggle('open')
                if (!wish.contains('open')) wish?.toggle('open')
                this.guestNumber = 0
            }
            if (!send.contains('open')) send?.toggle('open')
        },
    },
    mounted(){
      
    },
   methods:{
     sendWish(){
       const self = this
        this.loading = true
        const payload = {
          name: this.name,
          is_attended: this.isAttended,
          guest: this.guestNumber ? this.guestNumber : 0,
          description: this.wish,
          wedding_id: this.wedding.id,
        }

        this.$axios
          .$post('/api/v1/public/attendances', payload)
          .then((res) => {
            self.$swal.fire('Your wish sended!', res.message, 'success')
            self.resetValue()
            self.$emit('onGetAttendance')
          })
          .catch(() =>
            self.$swal.fire('Failed!', 'Ups, kamu telah mengirim!', 'error')
          )
          .finally(() => {
            this.loading = false
          })
    },
     resetValue() {
      this.isAttended = null
      this.guestNumber = null
      this.wish = null
    }
   }
}
</script>

<style scoped>
#guest {
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-out;
}

#guest.open {
  height: 80px;
}

#wish {
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-out;
}

#wish.open {
  height: auto;
}

#button-send {
  height: 0px;
  overflow: hidden;
  transition: height 300ms ease-out;
}

#button-send.open {
  height: auto;
}

.custom-radio {
  position: relative;
}

.custom-radio label {
  margin-left: 20px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #8c8c8c;
  cursor: pointer;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio :after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #8c8c8c;
}

/* daerah theme */
.theme1__wedding_wishes {
 color: #314260;
 @apply text-4xl capitalize font-fair italic font-bold
}

.theme1__btn {
  background-color: #314260;
  font-family: constantia;
  @apply px-6 py-5 text-white text-base rounded-2xl select-none shadow-md
}


.theme2__wedding_wishes {
 color: #525050;
 @apply text-lg uppercase font-fair
}

.theme2__btn {
  background-color: #DED4CB;
  font-family: constantia;
  @apply px-6 py-2 text-white text-base rounded-xl select-none shadow-md
}

</style>
