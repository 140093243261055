<template>
    <div>
        <div class="form-group">
            <label for="name">Title</label>
            <input id="name" v-model.lazy="theme.title" type="text" />
        </div>
        <div class="form-group">
            <label for="currency" class="mb-2">currency</label>
            <select id="currency" v-model.lazy="theme.currency" name="currency">
                <option value="idr">IDR - Indonesia</option>
                <option value="dollar">DOLLAR - US</option>
            </select>
        </div>
        <div class="form-group mt-2">
            <label for="price">Price</label>
            <input id="price" v-model.lazy="theme.price" type="number" min="0" />
        </div>
        <div>
            <div>Setting</div>
            <div class="grid grid-cols-4">
                <div v-for="(obj, index) in Object.keys(theme.setting)" :key="index" class="my-2 col-span-1">
                    <div class="flex items-center gap-2">
                        <input :id="obj" v-model="theme.setting[obj]" type="checkbox" class="border-none" :name="obj">
                        <label class="capitalize" :for="obj">{{ obj && obj.replace('_', ' ') }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-2 flex justify-end gap-2">
            <button class="flex flex-row gap-2 items-center text-white bg-primary rounded-md px-2 py-2"
                @click.prevent="submit()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
                </svg>
                <span>Save</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ThemeForm",
    props: {
        id: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            theme: {
                title: '',
                currency: 'idr',
                price: '0',
                setting: {
                    gift: true,
                    home: true,
                    rsvp: true,
                    gallery: true,
                    history: true,
                    timeline: true,
                    copyright: true,
                    countdown: true,
                    design_by: true,
                    navigation: true,
                    time_place: true,
                    health_protocol: true,
                    ig_filter: true,
                }

            }
        }
    },
    mounted() {
        if (this.id) this.getTheme()
    },
    methods: {
        getTheme() {
            this.$swal.fire('loading theme data')
            this.$swal.showLoading()

            this.$axios.$get(`/api/v1/themes/${this.id}`).then((res) => {
                if (res.success) this.theme = res.data
            })
                .finally(() => this.$swal.close())
        },
        submit() {
            const self = this

            self.$swal
                .fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Save it!',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        self.$swal.fire('Saving your data...')
                        self.$swal.showLoading()

                        if (self.id) {
                            self.update(self.theme)
                        } else {
                            self.store(self.theme)
                        }

                    }
                })
        },
        store(payload) {
            const self = this

            self.$axios.$post('/api/v1/themes', payload)
                .then((res) => {
                    if (res.success) {
                        self.$swal.hideLoading()
                        self.$swal
                            .fire('Saved!', res.message, 'success')
                            .then((res2) => {
                                if (res2.isConfirmed) {
                                    self.$router.push('/themes')
                                }
                            })
                    }
                })
                .catch(error => {
                    self.$swal.fire('Error!', error.response.data.message, 'error')
                })
        },
        update(payload) {
            const self = this

            self.$axios.$patch('/api/v1/themes/' + self.id, payload)
                .then((res) => {
                    if (res.success) {
                        self.$swal.hideLoading()
                        self.$swal
                            .fire('Saved!', res.message, 'success')
                            .then((res2) => {
                                if (res2.isConfirmed) {
                                    self.$router.push('/themes')
                                }
                            })

                    }
                })
                .catch(error => {
                    self.$swal
                        .fire('Error!', error.response.data.message, 'error')
                })
        }
    }
}
</script>