<template>
  <div
    id="pagination"
    class="px-2 w-full py-2 flex justify-end gap-4 items-center"
  >
    <div class="select-none">
      Showing {{ data.from }} to {{ data.to }} of {{ data.total }} results
    </div>
    <div>
      <button
        class="p-2 rounded-full text-black hover:bg-primary/10 hover:text-primary"
        :class="isFirstPage && 'border-gray-500 cursor-not-allowed'"
        :disabled="isFirstPage"
        @click.prevent="prevPage()"
      >
       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
      </svg>
      </button>
      <button
        class="p-2 rounded-full text-black hover:bg-primary/10 hover:text-primary"
        :class="isLastPage && 'border-gray-500 cursor-not-allowed'"
        :disabled="isLastPage"
        @click.prevent="nextPage()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 7l5 5m0 0l-5 5m5-5H6"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  computed:{
    isLastPage(){
      return this.data.current_page >= this.data.last_page
    },
    isFirstPage() {
      return this.data.current_page <= 1
    }
  },
  methods:{
    nextPage(){
      this.$emit('nextPage', this.data.current_page + 1)
    },
    prevPage(){
      this.$emit('nextPage', this.data.current_page - 1)
    }
  }
}
</script>
