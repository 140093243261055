<template>
    <div>
    <button
        class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2"  @click.prevent="addEvent()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
        </svg>
        <span>Tambah</span>
    </button>
    <div
      v-for="(event, index) in events"
      :key="index"
      class="bg-secondary/50 p-2 rounded-md my-4"
    >
      <div class="form-group">
        <label for="event-name">Event Name</label>
        <input id="event-name" v-model="event.name" type="text" />
      </div>

      <div class="form-group">
        <label for="event-address">Address</label>
        <input id="event-address" v-model="event.address" type="text" />
      </div>

      <div class="form-group">
        <label for="event-address">Address</label>
        <textarea
          id="event-address-detail"
          v-model="event.address_detail"
          name="event-address-detail"
          cols="30"
          rows="5"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="event-description">Description</label>
        <textarea
          id="event-description"
          v-model="event.description"
          name="event-description"
          cols="30"
          rows="5"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="google-map-link">Google map link</label>
        <input
          id="google-map-link"
          v-model="event.google_maps_link"
          type="text"
        />
      </div>

      <div class="form-group">
        <label for="event-date">Event Date</label>
        <input
          id="event-date"
          v-model="event.date"
          type="datetime-local"
        />
      </div>
      
       <div class="form-group">
        <label for="end-event-date">Event End Date</label>
        <input
          id="end-event-date"
          v-model="event.end_date"
          type="datetime-local"
        />
      </div>

      <div class="flex justify-end gap-1 my-2">
        <button
          class="flex flex-row gap-2 items-center bg-primary text-white rounded-md px-2 py-2"  @click.prevent="save(event)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
          </svg>
          <span>Simpan</span>
        </button>
        <button
          class="flex flex-row gap-2 items-center bg-red-500 text-white rounded-md px-2 py-2"  @click.prevent="deleteEvent(event)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
          <span>Hapus</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    props:{
      weddingCode: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
            events : [],
            id: ''
        }
    },
    mounted(){
      if(this.weddingCode) this.getWedding()
    },
    methods:{
       getWedding() {
           this.$axios.$get(`/api/v1/weddings/${this.weddingCode}/show`)
           .then(res => {
                if(res.data){
                  this.id = res.data.id
                  this.events = res.data.events.map((event) => {
                    const date = new Date(event.date)
                    const newDate = `${date.getFullYear()}-${
                      (date.getMonth()+1) > 9 ? (date.getMonth()+1) : '0' + (date.getMonth()+1)
                    }-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}T${
                      date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
                    }:${date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()}`

                    const endDate = new Date(event.end_date)
                    let newEndDate = ''
                    if(event.end_date){
                        newEndDate = `${endDate.getFullYear()}-${
                            (endDate.getMonth()+1) > 9 ? (endDate.getMonth()+1) : '0' + (endDate.getMonth()+1)
                          }-${endDate.getDate() > 9 ? endDate.getDate() : '0' + endDate.getDate()}T${
                            endDate.getHours() > 9 ? endDate.getHours() : '0' + endDate.getHours()
                          }:${endDate.getMinutes() > 9 ? endDate.getMinutes() : '0' + endDate.getMinutes()}`
                    }

                    return {
                      ...event,
                      date: newDate,
                      end_date: newEndDate ?? null
                    }
                  })
                }
           })
        },
        save(payload) {
          const self = this

          this.$swal
            .fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Save it!',
            })
            .then((result) => {
              if (result.isConfirmed) {
                self.$swal.fire('Saving your data...')
                self.$swal.showLoading()
                if (payload.id === '') {
                  self.$axios.$post('/api/v1/events', payload).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                } else {
                  // change date
                  const payload2 = payload
                  payload2.date = payload.date.replace('T', ' ') + ':00'
                  self. $axios.$put('/api/v1/events/' + payload.id,payload).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                }
              }
            })
        },

        addEvent() {
          const self = this
          const checkAdd = self.events.find((el) => el.id === '')
          if (checkAdd) {
            self.$swal.fire('Warning', 'Save your current event', 'warning')
          } else {
            self.events.push({
              id: '',
              name: '',
              address: '',
              address_detail: '',
              date: '',
              end_date: '',
              wedding_id: self.id,
              description: '',
            })
          }
        },
        deleteEvent(payload) {
          const self = this
          if (payload.id === '') {
            this.events.pop()
          } else {
            this.$swal
              .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  self.$swal.fire('Deleting your data...')
                  self.$swal.showLoading()

                  self.$axios.$delete('/api/v1/events/' + payload.id).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Deleted!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                }
              })
          }
        }
    }
}
</script>
