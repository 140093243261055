<template>
    <ul class="flex flex-row gap-2 py-3 text-sm select-none">
        <li v-for="(pathName, i) in newPath" :key="i" class="flex flex-row items-center" :class="i !== newPath.length -1 ? `hover:underline hover:text-primary cursor-pointer` : `text-gray-500`" @click="goToUrl(i)">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
            <span>{{ pathName }}</span>
        </li>
    </ul>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    path: {
      type: String,
      default: ''
    }
  },
    computed:{
        newPath() {
            const path = this.path.split('/')
            path.shift()

            return path
        }
    },
    methods:{
        goToUrl(index){
            const go = this.newPath.slice(0, index+1)
            this.$router.push('/' + go.join('/'))
        }
    }
}
</script>
