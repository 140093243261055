<template>
  <div>
    <div class="form-group">
      <label for="name">Name</label>
      <input id="name" v-model.lazy="user.name" type="text" />
    </div>

    <div class="form-group">
      <label for="email">Email</label>
      <input id="email" v-model.lazy="user.email" type="email" />
    </div>

    <div v-if="!id" class="form-group">
      <label for="password">password</label>
      <input id="password" v-model.lazy="user.password" type="password" />
    </div>

    <div v-if="!id" class="form-group">
        <select id="role" v-model="roleSelected" name="role">
            <option disabled>Please select role</option>
            <option
            v-for="(role, index) in roles"
            :key="index"
            :value="role.id"
            >
            {{ role.name }}
            </option>
        </select>
    </div>


    <div class="my-2 flex justify-end gap-2">
        <button class="flex flex-row gap-2 items-center text-white bg-primary rounded-md px-2 py-2" @click="submit">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
            </svg>
            <span>Save</span>
        </button>
    </div>
  </div>
</template>


<script>
export default {
    props:{
      id: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
            user : {
                id: '',
                name: '',
                email: '',
                password: '',
            },
            roles: [
                {
                    id: 1,
                    name: 'admin'
                },
                {
                    id: 2,
                    name: 'user'
                }
            ],
            roleSelected: null
        }
    },
    mounted(){
        if (this.id) this.getUser()
    },
    methods:{
        getUser() {
            this.$swal.fire('loading user data')
            this.$swal.showLoading()
            this.$axios.$get('/api/v1/users/' + this.id).then((res) => {
                    if (res.success) this.user = { ...res.data }
                })
                .finally(() => this.$swal.close())
        },
        submit() {
            const self = this

            self.$swal
            .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Save it!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                self.$swal.fire('Saving your data...')
                self.$swal.showLoading()

                if (self.user.id) {
                    self.$axios.$patch(
                        '/api/v1/users/' + self.user.id,
                        self.user
                    ).then((res) => {
                    if (res.success) {
                        self.$swal.hideLoading()
                        self.$swal
                        .fire('Saved!', res.message, 'success')
                        .then((res2) => {
                            if (res2.isConfirmed) {
                            self.getUser()
                            }
                        })
                    } else {
                        self.$swal.fire('Error!', res.message, 'error')
                    }
                    })
                } else {
                    let user = {...this.user}
                    if(self.roleSelected) user = {...self.user, role_id: this.roleSelected}
                    self.$axios.$post('/api/v1/users', user).then((res) => {
                    if (res.success) {
                        self.$swal.hideLoading()
                        self.$swal
                        .fire('Updated!', res.message, 'success')
                        .then((res2) => {
                            if (res2.isConfirmed) {
                                self.$router.go(-1)
                            }
                        })
                    } else {
                        self.$swal.fire('Error!', res.message, 'error')
                    }
                    })
                }
                }
            })
        }
    }
}
</script>
