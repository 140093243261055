<template>
  <div class="fixed bottom-5 right-5 z-50">
    <audio
      ref="audio"
      :src="url"
      preload
      loop
      muted
      x-webkit-airplay="deny"
    ></audio>
    <button
      id="play-btn"
      class="p-0.5 text-white rounded-full shadow-md focus:outline-none"
      :style="`background-color: ${color}`"
      name="play-btn"
      @click.prevent="play()"
    >
      <svg
        v-if="!status"
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 h-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
    props:{
      isPlaying: {
         type: Boolean,
         required: true,
      },
      url: {
        type: String,
        required: true,
      },
      color: {
          type: String,
          required: false,
          default: '#314260'
      }
    },
    data() {
        return {
            status: false
        }
    },
    watch:{
        isPlaying(val){
          if (val) {
            this.play()
          }
        },
    },
   methods:{
     play() {
        const audio = this.$refs.audio
        if (audio.paused) {
            audio.muted = false
            audio.play()
            this.status = true
        } else {
            audio.muted = true
            audio.pause()
            this.status = false
        }
    }
   }
}
</script>
