<template>
  <div>
    <button
        class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2"  @click.prevent="addGift()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
        </svg>
        <span>Tambah</span>
    </button>
  
    <div
      v-for="(gift, index) in gifts"
      :key="index"
      class="bg-secondary/50 p-2 rounded-md my-4"
    >
      <div class="form-group">
        <label for="gift_name">Name</label>
        <input id="gift_name" v-model.lazy="gift.name" type="text" />
      </div>

      <div class="form-group">
        <label for="account_name">Account Name</label>
        <input id="account_name" v-model.lazy="gift.account_name" type="text" />
      </div>

      <div class="form-group">
        <label for="account_number">Account Number</label>
        <input
          id="account_number"
          v-model.lazy="gift.account_number"
          type="text"
        />
      </div>

      <div class="flex justify-end gap-1 my-2">
        <button
          class="flex flex-row gap-2 items-center bg-primary text-white rounded-md px-2 py-2"  @click.prevent="save(gift)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
          </svg>
          <span>Simpan</span>
        </button>
        <button
          class="flex flex-row gap-2 items-center bg-red-500 text-white rounded-md px-2 py-2"  @click.prevent="deleteGift(gift)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
          <span>Hapus</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    props:{
      weddingCode: {
         type: String,
         required: false,
         default: ''
      },
    },
    data() {
        return {
            gifts : [],
            id: ''
        }
    },
    mounted(){
      if(this.weddingCode) this.getWedding()
    },
    methods:{
       getWedding() {
           this.$axios.$get(`/api/v1/weddings/${this.weddingCode}/show`)
           .then(res => {
                if(res.data){
                  this.id = res.data.id
                  this.gifts = res.data.gifts
                }
           })
        },
       save(payload) {
          const self = this

          this.$swal
            .fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Save it!',
            })
            .then((result) => {
              if (result.isConfirmed) {
                self.$swal.fire('Saving your data...')
                self.$swal.showLoading()
                if (payload.id === '') {
                  this.store(payload)
                } else {
                  this.update(payload)
                }
              }
            })
        },

        addGift() {
          const self = this
          const checkAdd = self.gifts.find((el) => el.id === '')
          if (checkAdd) {
            self.$swal.fire('Warning', 'Save your current timeline first...', 'warning')
          } else {
            self.gifts.push({
              id: '',
              name: '',
              account_name: '',
              account_number: '',
            })
          }
        },

        deleteGift(payload) {
          const self = this
          if (payload.id === '') {
            this.gifts.pop()
          } else {
            this.$swal
              .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  self.$swal.fire('Deleting your data...')
                  self.$swal.showLoading()

                  this.$axios.$delete('/api/v1/gifts/'+ payload.id).then((res) => {
                    if (res.success) {
                      self.$swal.hideLoading()
                      self.$swal
                        .fire('Deleted!', res.message, 'success')
                        .then((res2) => {
                          if (res2.isConfirmed) {
                            self.getWedding()
                          }
                        })
                    } else {
                      self.$swal.fire('Error!', res.message, 'error')
                    }
                  })
                }
              })
          }
        },
        store(payload){
          const self = this
          const data = {
            wedding_id: self.id,
            gifts: [payload],
          }

          this.$axios.$post('/api/v1/gifts', data).then((res) => {
            if (res.success) {
              self.$swal.hideLoading()
              self.$swal
                .fire('Saved!', res.message, 'success')
                .then((res2) => {
                  if (res2.isConfirmed) {
                    this.getWedding()
                  }
                })
            } else {
              self.$swal.fire('Error!', res.message, 'error')
            }
          })
        },
        update(payload){
          const self = this
           this.$axios.$put('/api/v1/gifts/' + payload.id, payload).then((res) => {
              if (res.success) {
                self.$swal.hideLoading()
                self.$swal
                  .fire('Saved!', res.message, 'success')
                  .then((res2) => {
                    if (res2.isConfirmed) {
                      this.getWedding()
                    }
                  })
              } else {
                self.$swal.fire('Error!', res.message, 'error')
              }
            })
        }
    }
}
</script>
